import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '@wndr/common/core/services/user.service';
import { assertNonNullWithReturn } from '@wndr/common/core/utils/assert-non-null';
import { NotificationService } from '@wndr/common/core/services/notification.service';
import { catchError, defer, endWith, map, of, repeat, startWith, Subject, take, takeWhile, tap, timer, withLatestFrom } from 'rxjs';
import { otpCodeMask } from '@wndr/common/core/utils/masks';
import { AsyncPipe } from '@angular/common';
import { catchValidationData } from '@wndr/common/core/utils/rxjs/catch-validation-error';
import { toggleExecutionState } from '@wndr/common/core/utils/rxjs/toggle-execution-state';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { logOutOutline } from 'ionicons/icons';
import { commonRoutePaths } from '@wndr/common/core/utils/route-paths/common-route-paths';
import { Router } from '@angular/router';

import { InputComponent } from '../controls/input/input.component';
import { AbstractModalComponent } from '../abstract-modal-component/abstract-modal-component';
import { ButtonDirective } from '../buttons/button.directive';
import { ModalWrapperComponent } from '../modal-wrapper/modal-wrapper.component';
import { InputErrorWrapperComponent } from '../error-wrappers/input-error-wrapper/input-error-wrapper.component';
import { FormErrorWrapperComponent } from '../error-wrappers/form-error-wrapper/form-error-wrapper.component';
import { TimePipe } from '../../pipes/time.pipe';
import { LoadingDirective } from '../../directives/loading.directive';

/** Confirm email dialog id. */
export const CONFIRM_EMAIL_DIALOG_ID = 'confirm-email-dialog';

/** Role to dismiss modal. */
export const CAN_DISMISS_CONFIRM_EMAIL_MODAL_ROLE = 'close-after-submission-role';

/** Interval between resending confirmation code. */
const RESEND_INTERVAL_MINUTES = 3;

/** Confirm email dialog data. */
export type ConfirmEmailDialogData = Readonly<{

	/** Whether user tries to confirm secondary email or not. */
	isSecondaryEmail: boolean;

	/** Whether need to send confirmation code immediately or not. */
	sendImmediately: boolean;

	/** Whether close button is shown. */
	isCloseButtonShown: boolean;

	/** Whether logout button is shown. */
	isLogoutButtonShown: boolean;
}>;

/** Dialog component to confirm email address. */
@Component({
	selector: 'wndrc-confirm-email-dialog',
	imports: [
		IonIcon,
		TimePipe,
		AsyncPipe,
		InputComponent,
		ReactiveFormsModule,
		ButtonDirective,
		ModalWrapperComponent,
		InputErrorWrapperComponent,
		FormErrorWrapperComponent,
		LoadingDirective,
	],
	templateUrl: './confirm-email-dialog.component.html',
	styleUrl: './confirm-email-dialog.component.css',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmEmailDialogComponent
	extends AbstractModalComponent<ConfirmEmailDialogData, boolean>
	implements OnInit {
	private readonly userService = inject(UserService);

	private readonly notificationService = inject(NotificationService);

	private readonly timerInitiator$ = new Subject<void>();

	private readonly fb = inject(NonNullableFormBuilder);

	private readonly router = inject(Router);

	/** App routes. */
	protected readonly appRoutes = commonRoutePaths;

	/** Whether process is running or not. */
	protected readonly isLoading = signal(false);

	/** Form element ID. */
	protected readonly formId = 'confirm-email-form';

	/** Whether user tries to confirm secondary email or not. */
	protected readonly isSecondaryEmail = computed(() => this.modalData().isSecondaryEmail);

	private readonly sendImmediately = computed(() => this.modalData().sendImmediately);

	/** Whether close button is shown. */
	protected readonly isCloseButtonShown = computed(() => this.modalData().isCloseButtonShown);

	/** Whether logout button is shown. */
	protected readonly isLogoutButtonShown = computed(() => this.modalData().isLogoutButtonShown);

	/** @inheritdoc */
	protected override readonly id = CONFIRM_EMAIL_DIALOG_ID;

	private readonly timerScheduledEnd$ = defer(() => of(new Date(
		(new Date()).getTime() +
		RESEND_INTERVAL_MINUTES * 60000,
	)));

	/** Timer. */
	protected readonly timer$ = timer(0, 1000).pipe(
		withLatestFrom(this.timerScheduledEnd$),
		map(([_, scheduledEndTime]) => scheduledEndTime),
		map(scheduledEndTime => {
			const currentDate = new Date();
			return Math.floor((scheduledEndTime.getTime() - currentDate.getTime()) / 1000);
		}),
		takeWhile(seconds => seconds > 0),
		endWith(0),
		repeat({ delay: () => this.timerInitiator$ }),
	);

	/** Whether time is completed. */
	protected readonly isTimerCompleted$ = this.timer$.pipe(
		map(seconds => seconds === 0),
		startWith(false),
	);

	/** Confirmation code mask. */
	protected readonly confirmationCodeMask = otpCodeMask;

	/** Control for code input. */
	protected readonly confirmationForm = this.fb.group({
		code: this.fb.control('', [
			Validators.required,
			Validators.minLength(6),
			Validators.maxLength(6),
		]),
	});

	public constructor() {
		super();
		addIcons({ logOutOutline });
	}

	/** @inheritdoc */
	public override ngOnInit(): void {
		super.ngOnInit();

		if (this.sendImmediately()) {
		// Emulate a click to avoid code duplication outside the component (code request)
		// and request code when opening the dialog.
			this.onResendButtonClick();
		}
	}

	/** Handle 'ngSubmit' of the confirmation form. */
	protected onSubmit(): void {
		this.confirmationForm.markAllAsTouched();
		if (this.confirmationForm.invalid) {
			return;
		}

		const { code } = this.confirmationForm.value;

		this.userService.confirmEmail(assertNonNullWithReturn(code), this.isSecondaryEmail()).pipe(
			take(1),
			toggleExecutionState(this.isLoading),
			catchValidationData(this.confirmationForm),
		)
			.subscribe({
				next: () => this.close({ data: true, role: CAN_DISMISS_CONFIRM_EMAIL_MODAL_ROLE }),
			});
	}

	/** On resend button click. */
	protected onResendButtonClick(): void {
		this.userService.sendConfirmationCode(this.isSecondaryEmail()).pipe(
			take(1),
			catchError((error: unknown) => {
				this.notificationService.notify('warning', 'Something went wrong. Please try again');
				throw error;
			}),
		)
			.subscribe({
				next: () => {
					this.notificationService.notify('success', 'Code has been sent to your email');
					this.timerInitiator$.next();
				},
			});
	}

	/** Handles logout button click. */
	protected onLogoutButtonClick(): void {
		this.userService.logout().pipe(
			tap(() => {
				this.close({ data: false, role: CAN_DISMISS_CONFIRM_EMAIL_MODAL_ROLE });
			}),
		)
			.subscribe(() => {
				this.router.navigateByUrl(this.appRoutes.auth.children.login.url);
			});
	}
}
